#boxOffice-venue-svg {
  .clickableSeat {
    cursor: pointer;
    fill: #bae6fd !important;
    transition: all 0.3s;
    &:hover {
      fill: #7dd3fc !important;
    }

    &.sectionSelected {
      fill: #68d391 !important;
    }
  }
  .unclickableSeat {
    fill: #93969a !important;
    cursor: not-allowed;
  }
}

#boxOffice-venue-section-svg {
  .availableSection {
    fill: #bae6fd !important;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      fill: #7dd3fc !important;
    }
  }
  .selectedSection {
    fill: #68d391 !important;
    cursor: pointer;
  }
  .lockSection {
    fill: #f87171 !important;
    cursor: not-allowed;
  }
  .soldSection {
    fill: #64748b !important;
    cursor: not-allowed;
  }
}
