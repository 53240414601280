// stylelint-disable selector-no-qualifying-type

.fade {
  opacity: 0;
  @include transition($transition-fade);

  &.show {
    opacity: 1;
  }
}

.collapse {
  display: none;
  &.show {
    display: block;
  }
}

tr {
  &.collapse.show {
    display: table-row;
  }
}

tbody {
  &.collapse.show {
    display: table-row-group;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

.moveSlideUp {
  position: relative;
  top: 90%;
  animation: moveUpFrame 0.25s ease-out both;
}

.moveSlideLeft {
  position: relative;
  left: 90%;
  animation: moveLeftFrame 0.25s ease-out both;
}

@keyframes moveUpFrame {
  to {
    top: 0;
  }
}

@keyframes moveLeftFrame {
  to {
    left: 0%;
  }
}
