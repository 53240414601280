body {
  font-family: "Poppins", sans-serif;
}

body.fontLoaded {
  font-family: "Poppins", sans-serif;
}

#app {
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
}
.header-container {
  padding: 5px 0 5px 0;
  margin-bottom: 0;
}

.content-container {
  display: inline-flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
  @media (max-width: 992px) {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.clickableSection {
  background-color: #c1f0f6;
  fill: #c1f0f6;
  color: #ffffff;
  cursor: default;
}

.clickableSection:hover {
  background-color: #63d1f4;
  fill: #7ec0ee;
  cursor: pointer;
}

.unclickableSection {
  background-color: #a0a0a0;
  fill: #a0a0a0;
  cursor: not-allowed;
}

.unclickableSection:hover {
  background-color: #a0a0a0;
  fill: #a0a0a0;
  cursor: not-allowed;
}

.selectedSeat {
  fill: #00bfff !important;
  background-color: #00bfff;
}

.selectableSeat {
  fill: #ebebeb !important;
  background-color: #ebebeb;
}

.seatSectionClickable {
  fill: #bae6fd !important;
  cursor: pointer;
  background-color: #bae6fd;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.4s;
  pointer-events: auto;
  user-select: none;

  &:hover {
    background-color: #7dd3fc;
    fill: #7dd3fc !important;
  }
}

.seatSectionPath {
  pointer-events: none;
  user-select: none;
}
